import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PulseLoader from "react-spinners/PulseLoader";

import DataTable from 'react-data-table-component';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
);

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export function Ratings() {
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "https://remelo.onrender.com/",
        }
    };
    const columns = [
        {
            name: 'Name',
            selector: row => row["name"],
        },
        {
            name: 'Rating',
            selector: row => row["rating"],
        },
    ];

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: { x: { title: { display: true, text: 'Time' } }, y: { title: { display: true, text: 'Ratings' } } },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'True Skill (95% Confidence Interval)',
            },
            colors: { enabled: true }
        },
    };
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sortedRating, setSortedRating] = useState(null);
    useEffect(() => {
        const getRankings = async () => {
            try {
                const response = await axios.get('https://games-db-webserver.onrender.com/games/rankings/', axiosConfig);
                const rankings = response.data.data[0]['true_skill_95'];
                const labels = response.data.data[0]['labels'];
                const current_ratings = response.data.data[0]['current_ratings']
                const dataset_for_chart = rankings.map((player) => {
                    return {
                        label: player["name"],
                        data: player["ratings"],
                    }
                });
                const data = {
                    labels: labels,
                    datasets: dataset_for_chart,
                };
                setData(data);
                setSortedRating(current_ratings);
                setIsLoading(false);
            } catch (error) {
            }
        };
        getRankings();
    }, []);
    return isLoading ?
        <PulseLoader
            cssOverride={override}
            loading={isLoading}
            size={15}
        /> : <>

            <div style={{ position: "relative", marginBottom: "1%", padding: "0% 10%" }}>
                <DataTable
                    title={"Live Ratings"}
                    columns={columns}
                    data={sortedRating}
                />
            </div>
            <div style={{ height: "50vh", position: "relative", marginBottom: "1%", padding: "0% 5%" }}>
                <Line options={options} data={data} />
            </div>
        </>;
};
