import './App.css';
import { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Ratings } from './pages/Ratings';

function App() {

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "https://remelo.onrender.com/",
    }
  };

  const addGame = async () => {
    try {
      if (playerOne === playerTwo) {
        throw new Error("Please make sure that both players are not the same set of people");
      }
      const response = await axios.post('https://games-db-webserver.onrender.com/games', {
        player_one: playerOne,
        player_two: playerTwo
      }, axiosConfig);
      console.log(response.data);
      if (response.data.code === 200) {
        if (!alert('Game added successfully')) { window.location.reload(); }
      }

    } catch (error) {
      alert("Oops something went wrong.")
    }
  }

  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);

  const players = [
    { value: 'felipe', label: 'Felipe' },
    { value: 'pavel', label: 'Pavel' },
    { value: 'rakshit', label: 'Rakshit' },
    { value: 'rikin', label: 'Rikin' },
    { value: 'samuel', label: 'Samuel' },
    { value: 'sumit', label: 'Sumit' },
  ]


  return (
    <div className="App">
      <h1>PaidEX Remediation Table Tennis</h1>
      <h2>Add Game</h2>
      <h3>Player 1</h3>
      <i>Set <b>Player 1</b> as the player who has <b>won</b> the game.</i>
      <br />
      <div style={{ marginBottom: "1%", padding: "1% 5% 0% 5%" }}>
        <Select options={players} onChange={(value) => setPlayerOne(value.label)} />
      </div>
      <h3>Player 2</h3>
      <div style={{ marginBottom: "1%", padding: "0% 5% 0% 5%" }}>
        <Select options={players} onChange={(value) => setPlayerTwo(value.label)} />
      </div>
      <div style={{ marginBottom: "1%", padding: "0% 5% 0% 5%", display: 'flex' }}>
        <button style={{ flexGrow: 1 }} onClick={addGame}><h3>Add</h3></button>
      </div>
      <Ratings />
    </div >
  );
}

export default App;
